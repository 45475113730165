import React, { useContext } from 'react'
import Layout from '../components/Global/Layout'
import { graphql } from 'gatsby'
import WhyUs from '../components/WhyUs'
import Loader from '../components/Global/Loader'
import { GlobalStateContext } from '../context/GlobalContextProvider'

export const query = graphql`
  {
    allContentfulWhyUsPage {
      edges {
        node {
          firstSection {
            internal {
              content
            }
          }
          secondSection {
            internal {
              content
            }
          }
          trademarkSearchImages {
            file {
              url
            }
          }
          tagline {
            internal {
              content
            }
          }
          serviceLevel {
            internal {
              content
            }
          }
          ourValuesTitle
          ourValuesIcons {
            description
            file {
              url
            }
            title
          }
          button {
            internal {
              content
            }
          }
          technologyPartnersTitle
          technologyPartners {
            description
            file {
              url
            }
          }
          team {
            internal {
              content
            }
          }
          expertiseIcon {
            file {
              url
            }
          }
          teamGallery {
            description
            file {
              url
            }
            title
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

const WhyPage = (props) => {
  const state = useContext(GlobalStateContext)

  if (!state.locale) {
    return (
      <Layout url='/why-us' locale={state.locale}>
        <Loader loading />
      </Layout>
    )
  }

  const whyUsData = props.data?.allContentfulWhyUsPage.edges?.filter(
    (node) => node.node.node_locale === state.locale
  )[0].node

  return (
    <Layout
      locale={state.locale}
      title={JSON.parse(whyUsData?.seoContent.internal.content).title}
      desc={JSON.parse(whyUsData?.seoContent.internal.content).desc}
      url='/why-us'
    >
      <WhyUs whyUsData={whyUsData} />
    </Layout>
  )
}

export default WhyPage
